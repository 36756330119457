import { type AppProps } from 'next/app';

import NextTopLoader from 'nextjs-toploader';

import 'newrelic';

import ErrorBoundary from '../components/atoms/ErrorBoundary/ErrorBoundary';

import '../styles/global.scss';

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ErrorBoundary>
      <NextTopLoader color="#1873e8" zIndex={9999} showSpinner={true} />
      <Component {...pageProps} />
    </ErrorBoundary>
  );
};

export default MyApp;
