import { notifyAirbrake } from './notifiers/notifyAirbrake';

export const notifyErrorClient = (
  error: Error,
  params?: Record<string, unknown>
) => {
  notifyAirbrake({
    error,
    params,
    errorSide: 'client',
  });
};
