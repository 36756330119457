// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Notifier as AirbrakeInstance } from 'airbrake';

import appPackage from '../../../../package.json';

let instance = null;

type TErrorSide = 'server' | 'client';

type INoticeError = Error & {
  isOperational?: boolean;
};

interface INotifyAirbrake {
  error: INoticeError;
  errorSide: TErrorSide;
  params?: unknown;
}

interface INoticeParams {
  context: {
    environment: string;
    version: string;
    isOperational: boolean;
  };
}

export function notifyAirbrake({
  error,
  errorSide,
  params = {},
}: INotifyAirbrake) {
  const airbrake = getAirbrakeInstance(errorSide);

  if (!airbrake) {
    return;
  }

  const filters = [
    monitorErrorsOnlyOnProduction,
    addProjectVersion,
    removeOperationalErrors,
  ];

  filters.forEach((filter) => airbrake.addFilter(filter));

  airbrake.notify({
    error,
    params,
    context: {
      isOperational: error?.isOperational || false,
    },
  });
}

function getAirbrakeInstance(errorSide: TErrorSide) {
  if (instance == null) {
    const projectId = Number(process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID);
    const projectKey = process.env.NEXT_PUBLIC_AIRBRAKE_KEY;
    const environment =
      process.env.NEXT_PUBLIC_AIRBRAKE_ENVIRONMENT || 'development';

    if (!projectId || !projectKey) {
      return;
    }

    instance = new AirbrakeInstance({
      projectId,
      projectKey,
      environment: `${environment} - ${errorSide}`,
    });
  }

  return instance;
}

function monitorErrorsOnlyOnProduction(notice: INoticeParams) {
  if (/production/.test(notice.context.environment)) {
    return notice;
  }

  return null;
}

function addProjectVersion(notice: INoticeParams) {
  if (notice.context) {
    notice.context.version = appPackage.version;
  }

  return notice;
}

function removeOperationalErrors(notice: INoticeParams) {
  if (notice.context.isOperational) {
    return null;
  }

  return notice;
}
