import { Component, type ReactNode } from 'react';

import { notifyErrorClient } from '../../../services/notifyError/client';

interface IErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<IErrorBoundaryProps> {
  componentDidMount() {
    window.addEventListener('error', this.handleError);
  }

  componentDidCatch(error, info) {
    notifyErrorClient(error, {
      info,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleError);
  }

  handleError({ error }) {
    notifyErrorClient(error);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
